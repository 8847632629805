import React  from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Auth } from "aws-amplify";
import { SplitButton } from 'primereact/splitbutton';


export const AppTopbar = (props) => {

    const items = [
        {
            label: 'Sign Out',
            icon: 'pi pi-sign-out',
            command: (e) => {
                Auth.signOut();
            }
        }
    ]


    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/vcc-logo.png' : 'assets/layout/images/vcc-logo.png'} alt="logo"/>
                {/* <span>SAKAI</span> */}
            </Link>

            {/* <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button> */}

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    {/* <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-calendar"/>
                            <span>Events</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-cog"/>
                            <span>Settings</span>
                        </button>
                    </li> */}
                    <li>
                        <SplitButton icon="pi pi-user" model={items} tooltip={props.userData} tooltipOptions={{ position: 'bottom' }} className="p-button-secondary" />
                    </li>
                </ul>
        </div>
    );
}
