import React, { useState, useEffect, useRef } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { VccService } from '../service/VccService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { Tag } from 'primereact/tag';
import { Badge } from 'primereact/badge';
import { confirmDialog } from 'primereact/confirmdialog';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import QRCode from 'qrcode';


export const VccContacts = () => {

    let emptyContact = {
        uid: '',
        contactUID: '',
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        mobile_phone: '',
        user_avatar_data: '',
        organization: '',
        job_title: '',
        work_url: '',
        work_email: '',
        work_phone: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        county: '',
        postal_code: '',
        country: '',
        linkedin_url: '',
        facebook_url: '',
        instagram_url: '',
        youtube_url: '',
        whats_app_url: '',
        zalo_url: '',
        notes: '',
        rev: '',
        created_on: ''
    };
    const [contact, setContact] = useState(emptyContact);
    const [contactSynced, setContactSynced] = useState(false);
    const [vcfFileUrl, setVcfFileUrl] = useState("");
    const [primaryRecordRevision, setPrimaryRecordRevision] = useState("");
    const [qrCodeData, setQRCodeData] = useState("");
    const [userAvatarUrl, setUserAvatarUrl] = useState("");
    const userAvatarUploader = useRef();
    const [editMode, setEditMode] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [contactDialog, setContactDialog] = useState(false);

    const [globalFilter, setGlobalFilter] = useState("");
    const treeTableFuncMap = {
        'globalFilter': setGlobalFilter,
    };
    const [treeTableNodes, setTreeTableNodes] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState({});
    const [expandAll, setExpandAll] = useState(false);

    const vccService = useRef(new VccService());

    const toast = useRef(null);


    useEffect(() => {
        vccService.current.getAllContacts().then(data => {
            setTreeTableNodes(data); 
            toggleAll(data);
        });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getHeader = (globalFilterKey) => {
        return (
            <div className="grid">
                <div className="col" style={{ textAlign: 'left' }}>
                    <Button label="Add" icon="pi pi-plus" className="p-button-success mr-3" onClick={() => addContact()}/>
                    <Button tooltip={expandAll ? "Collapse" : "Expand"} icon={expandAll ? "pi pi-chevron-circle-up" : "pi pi-chevron-circle-down"} className="p-button-secondary" onClick={() => toggleAll()}/>
                </div>
                <div className="col">
                    <div className="text-right">
                        <div className="p-input-icon-left">
                            <i className="pi pi-search"></i>
                            <InputText type="search" value={globalFilter} onInput={(e) => treeTableFuncMap[`${globalFilterKey}`](e.target.value)} placeholder="Search" size="25" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    let header = getHeader('globalFilter');

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _contact = { ...contact };
        _contact[`${name}`] = val;

        if (!editMode && (name === 'first_name' || name === 'last_name' || name === 'organization')) {
            updateContactUID(_contact);
        }

        setContact(_contact);
    }

    const updateContactUID = (c) => {
        let cUID = ("" + (c['organization'] ? (c['organization'] + "--") : "") + (c['first_name'] ? (c['first_name'] + "-") : "") + (c['last_name'] ? (c['last_name']) : ""));
        cUID = cUID.toLowerCase()
                    .normalize("NFD").replace(/\p{Diacritic}/gu, '')
                    .replaceAll(/[/&()",'.\\\\]/g, '')
                    .replaceAll(/ /g, '')
                    .trim();

        c['contactUID'] = cUID;
    }

    const toggleAll = (data) => {
        if (expandAll) {
            setExpandedKeys({});
        } else {
            let _expandedKeys = {};
            let nodes = data ? data : treeTableNodes;
            nodes.forEach(node => _expandedKeys[`${node.key}`] = true);
            setExpandedKeys(_expandedKeys); 
        }

        setExpandAll(!expandAll);
    };

    const expandNode = (node) => {
        let _expandedKeys = {...expandedKeys};
        _expandedKeys[node] = true;
        setExpandedKeys(_expandedKeys); 
    };

    const addContact = () => {
        let newContact = JSON.parse(JSON.stringify(emptyContact));
        newContact.uid = Math.random().toString(36).slice(2);
        setContact(newContact);

        setEditMode(false);

        setContactDialog(true);
    };

    const archiveContacts = (entry) => {
        confirmDialog({
            message: `Are you sure you want to archive ${entry.leaf ? 'contact' : 'organization' }: ${entry.key} ?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptIcon: 'pi pi-check',
            accept: () => {
                vccService.current.archiveContacts(entry.leaf ? [entry.key] : entry.children.map(c => c.key), treeTableNodes).then(data => {
                    setTreeTableNodes(data['contacts']);

                    let _globalFilter = globalFilter || '';
                    setGlobalFilter(' ');
                    setGlobalFilter(_globalFilter);

                    toast.current.show({ severity: 'success', summary: 'Success', detail: `${entry.leaf ? 'Contact' : 'All contacts of organization' } archived`, life: 5000 });
                })
            },
            rejectIcon: 'pi pi-times',
            reject: () => {}
        });
    };

    const editContact = (contact) => {
        vccService.current.getContact(contact.data.uid).then(ctt => {
            populateContactData(ctt.data);
            setContactSynced(ctt.isSynced);
            setUserAvatarUrl(ctt.data.user_avatar_data ? ctt.avatarUrl : '');
            setVcfFileUrl(ctt.vcfFileUrl);
            setPrimaryRecordRevision(ctt.rev);
            QRCode.toDataURL(ctt.vcfFileUrl, { errorCorrectionLevel: 'M' }, function (err, vcfFileUrlEncodedData) { setQRCodeData(vcfFileUrlEncodedData); });

            setEditMode(true);

            setContactDialog(true);
        });
    };

    const populateContactData = (ctt) => {
        if (!ctt['zalo_url']) {
            ctt['zalo_url'] = '';
        }
        if (!ctt['created_on']) {
            ctt['created_on'] = '';
        }

        setContact({ ...ctt });
    }

    const saveContact = () => {
        setSubmitted(true);

        if (contact.first_name.trim() && contact.last_name.trim() && contact.mobile_phone.trim() && contact.contactUID.trim()) {
            if (editMode) {
                vccService.current.updateContact(contact).then(data => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Contact Updated', life: 3000 });
                });
            } else {
                if (contactExists(contact.contactUID)) {
                    return;
                }
                vccService.current.addContact(contact, treeTableNodes).then(data => {
                    setTreeTableNodes(data['contacts']);
                    expandNode(data['organization']);
                    setGlobalFilter(contact.contactUID);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Contact Added', life: 3000 });
                });
            }

            hideDialog();
        }
    };

    const contactExists = (uid) => {
        for (let i = 0; i < treeTableNodes.length; i++) {
            for (let j = 0; j < treeTableNodes[i]['children'].length; j++) {
                if (treeTableNodes[i]['children'][j]['key'].trim() === uid.trim()) {
                    return true;
                }
            }
        }

        return false;
    }

    const resetContactDialogData = () => {
        setContact(emptyContact);
        setUserAvatarUrl("");
        setVcfFileUrl("");
        setPrimaryRecordRevision("");
        setQRCodeData("");
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTimeout(() => resetContactDialogData(), 100);
        setContactDialog(false);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.leaf && <Button tooltip="Edit Contact" tooltipOptions={{ position: 'top' }} icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editContact(rowData)} />}
                <Button tooltip={rowData.leaf ? "Archive Contact" : "Archive Organization"} tooltipOptions={{ position: 'top' }} icon="pi pi-inbox" className={`p-button-rounded mr-2 ${ rowData.leaf ? 'p-button-warning' : 'p-button-danger'}`} style={{marginLeft: (rowData.leaf ? '' : '2.8rem')}} onClick={() => archiveContacts(rowData)} />
            </div>
        );
    }

    const contactDialogFooter = (
        <div className="grid">
            <div className="col" style={{ textAlign: 'left' }}>
                { editMode && <Tag value={ contactSynced ? "Synced" : "Not-Synced" } icon={ contactSynced ? "pi pi-check-circle" : "pi pi-circle" } className={ contactSynced ? "p-tag p-tag-warning" : "p-tag p-tag-danger" } style={{ marginTop: '5px' }}/> }
            </div>
            <div className="col">
                <Button label="Cancel" icon="pi pi-times" className="p-button-text p-button-secondary" onClick={hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveContact} />
            </div>
        </div>
    );

    const toBase64 = (fileBlob) => {
        return new Promise(resolve => {
          var reader = new FileReader();
          reader.onload = function(event) {
            if (event && event.target) {
              resolve(event.target.result);
            }
          };
          
          reader.readAsDataURL(fileBlob);
        });
      };

    const handleUserAvatarUpload = (e) => {
        const file = e.files[0];
        toBase64(file).then(result => { 
            contact.user_avatar_data = ("" + result).split("base64,")[1]; 
            if (editMode) {
                vccService.current.updateContact(contact);
            }
        });
        try {
            if (editMode) {
                vccService.current.updateAvatar(contact.contactUID, file)
                    .then((avatarUrl) => {
                        setUserAvatarUrl(avatarUrl);
                        userAvatarUploader.current.clear();
                    });
            } else {
                vccService.current.uploadTemporaryAvatar(contact.uid, file)
                    .then((avatarUrl) => {
                        setUserAvatarUrl(avatarUrl);
                        userAvatarUploader.current.clear();
                    });
            }
        } catch (err) {
            console.debug(err);
        }
    };


    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <h3>Contacts</h3>
                    <TreeTable value={treeTableNodes} header={header} globalFilter={globalFilter} expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)}>
                        {/* <Column field="name" header="Name" filter filterPlaceholder="Filter by Name" filterMatchMode="contains" headerStyle={{ width: '60%' }} expander /> */}
                        <Column field="name" header="Name" headerStyle={{ width: '57%' }} expander />
                        {/* <Column field="uid" header="UID" filter filterPlaceholder="Filter by UID" filterMatchMode="contains" headerStyle={{ width: '33%' }} /> */}
                        <Column field="uid" header="UID" headerStyle={{ width: '35%' }} />
                        <Column body={actionBodyTemplate} headerStyle={{ width: '8%' }} />
                    </TreeTable>

                    <Dialog visible={contactDialog} style={{ width: '480px' }} header={ editMode ? "Edit Contact Details" : "Add New Contact" } modal className="p-fluid" footer={contactDialogFooter} onHide={hideDialog}>
                        { userAvatarUrl &&  
                        <div style={{ textAlign: 'center' }}>
                            <Image src={userAvatarUrl} alt="User Avatar" width="150" className="mt-0 mx-auto mb-5 block" preview />
                            <FileUpload ref={ref => { userAvatarUploader.current = ref; }} mode="basic" customUpload uploadHandler={handleUserAvatarUpload} auto accept="image/*" chooseOptions={{ label: 'Change Avatar', icon: 'pi pi-fw pi-image' }} className="mt-0 mx-auto mb-5 block"/>
                        </div>
                        }
                        { !userAvatarUrl && 
                        <div style={{ textAlign: 'center' }}>
                            <FileUpload ref={ref => { userAvatarUploader.current = ref; }} mode="basic" customUpload uploadHandler={handleUserAvatarUpload} auto accept="image/*" chooseOptions={{ label: 'Upload Avatar', icon: 'pi pi-fw pi-image' }} className="mt-5 mx-auto mb-5 block"/>
                        </div>
                        }
                        <br/>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="first_name" className="p-text-secondary">First Name *</label>
                                <InputText id="first_name" value={contact.first_name} onChange={(e) => onInputChange(e, 'first_name')} className={classNames({ 'p-invalid': submitted && !contact.first_name })} required/>
                                {submitted && !contact.first_name && <small className="p-invalid vcc-invalid">First Name is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="last_name" className="p-text-secondary">Last Name *</label>
                                <InputText id="last_name" value={contact.last_name} onChange={(e) => onInputChange(e, 'last_name')} className={classNames({ 'p-invalid': submitted && !contact.last_name })} required/>
                                {submitted && !contact.last_name && <small className="p-invalid vcc-invalid">Last Name is required.</small>}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="mobile_phone" className="p-text-secondary">Mobile Phone *</label>
                            <InputText id="mobile_phone" value={contact.mobile_phone} onChange={(e) => onInputChange(e, 'mobile_phone')} className={classNames({ 'p-invalid': submitted && !contact.mobile_phone })} required />
                            {submitted && !contact.mobile_phone && <small className="p-invalid vcc-invalid">Mobile Phone is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="email" className="p-text-secondary">Email</label>
                            <InputText id="email" value={contact.email} onChange={(e) => onInputChange(e, 'email')} />
                        </div>

                        <Divider />
                        <br/>

                        <div className="field">
                            <label htmlFor="organization" className="p-text-secondary">Organization</label>
                            <InputText id="organization" value={contact.organization} onChange={(e) => onInputChange(e, 'organization')} />
                        </div>
                        <div className="field">
                            <label htmlFor="job_title" className="p-text-secondary">Job Title</label>    
                            <InputText id="job_title" value={contact.job_title} onChange={(e) => onInputChange(e, 'job_title')} />
                        </div>
                        <div className="field">
                            <label htmlFor="work_phone" className="p-text-secondary">Work Phone</label>
                            <InputText id="work_phone" value={contact.work_phone} onChange={(e) => onInputChange(e, 'work_phone')} />
                        </div>
                        <div className="field">
                            <label htmlFor="work_email" className="p-text-secondary">Work Email</label>
                            <InputText id="work_email" value={contact.work_email} onChange={(e) => onInputChange(e, 'work_email')} />
                        </div>
                        <div className="field">
                            <label htmlFor="work_url" className="p-text-secondary">Website</label>
                            <InputText id="work_url" value={contact.work_url} onChange={(e) => onInputChange(e, 'work_url')} />
                        </div>

                        <Divider />
                        <br/>

                        <div className="field">
                            <label htmlFor="address_line_1" className="p-text-secondary">Address Line 1</label>
                            <InputText id="address_line_1" value={contact.address_line_1} onChange={(e) => onInputChange(e, 'address_line_1')} />
                        </div>
                        <div className="field">
                            <label htmlFor="address_line_2" className="p-text-secondary">Address Line 2</label>
                            <InputText id="address_line_2" value={contact.address_line_2} onChange={(e) => onInputChange(e, 'address_line_2')} />
                        </div>
                        <div className="field">
                            <label htmlFor="city" className="p-text-secondary">Town/City</label>
                            <InputText id="city" value={contact.city} onChange={(e) => onInputChange(e, 'city')} />
                        </div>
                        <div className="field">
                            <label htmlFor="county" className="p-text-secondary">County</label>
                            <InputText id="county" value={contact.county} onChange={(e) => onInputChange(e, 'county')} />
                        </div>
                        <div className="field">
                            <label htmlFor="postal_code" className="p-text-secondary">Postcode</label>
                            <InputText id="postal_code" value={contact.postal_code} onChange={(e) => onInputChange(e, 'postal_code')} />
                        </div>
                        <div className="field">
                            <label htmlFor="country" className="p-text-secondary">Country</label>
                            <InputText id="country" value={contact.country} onChange={(e) => onInputChange(e, 'country')} />
                        </div>

                        <Divider />
                        <br/>

                        <div className="field">
                            <label htmlFor="linkedin_url" className="p-text-secondary">LinkedIn</label>
                            <InputText id="linkedin_url" value={contact.linkedin_url} onChange={(e) => onInputChange(e, 'linkedin_url')} />
                        </div>
                        <div className="field">
                            <label htmlFor="facebook_url" className="p-text-secondary">Facebook</label>
                            <InputText id="facebook_url" value={contact.facebook_url} onChange={(e) => onInputChange(e, 'facebook_url')} />
                        </div>
                        <div className="field">
                            <label htmlFor="instagram_url" className="p-text-secondary">Instagram</label>
                            <InputText id="instagram_url" value={contact.instagram_url} onChange={(e) => onInputChange(e, 'instagram_url')} />
                        </div>
                        <div className="field">
                            <label htmlFor="youtube_url" className="p-text-secondary">Youtube</label>
                            <InputText id="youtube_url" value={contact.youtube_url} onChange={(e) => onInputChange(e, 'youtube_url')} />
                        </div>
                        <div className="field">
                            <label htmlFor="whats_app_url" className="p-text-secondary">WhatsApp</label>
                            <InputText id="whats_app_url" value={contact.whats_app_url} onChange={(e) => onInputChange(e, 'whats_app_url')} />
                        </div>
                        <div className="field">
                            <label htmlFor="zalo_url" className="p-text-secondary">Zalo</label>
                            <InputText id="zalo_url" value={contact.zalo_url} onChange={(e) => onInputChange(e, 'zalo_url')} />
                        </div>

                        <Divider />
                        <br/>

                        <div className="field">
                            <label htmlFor="notes" className="p-text-secondary">Notes</label>
                            <InputTextarea id="notes" value={contact.notes} rows={5} cols={20} onChange={(e) => onInputChange(e, 'notes')} />
                        </div>

                        <Divider />
                        <br/>

                        <div className="field">
                            <label htmlFor="contactUID" className="p-text-secondary">Contact Unique ID *</label>
                            <InputText id="contactUID" value={contact.contactUID} onChange={(e) => onInputChange(e, 'contactUID')} className={classNames({ 'p-invalid': submitted && (!contact.contactUID || contactExists(contact.contactUID)) })} style={{ backgroundColor: '#FFE082', color: '#121212' }} disabled={editMode} required />
                            {submitted && !contact.contactUID && <small className="p-invalid vcc-invalid">Contact Unique ID is required.</small>}
                            {submitted && contactExists(contact.contactUID) && <small className="p-invalid vcc-invalid">Contact Unique ID already exists.</small>}
                        </div>

                        { editMode && 
                        <>
                            <Divider />
                            <br/>

                            <div className="formgroup-inline">
                                <div className="field col">
                                    <span>
                                        <label htmlFor="vcfFileUrl" className="p-text-secondary">vCard URL </label>
                                        {primaryRecordRevision && primaryRecordRevision.trim() !== '' && <Badge value={`rev: ${primaryRecordRevision}`} severity="success"></Badge>}
                                    </span>
                                    <InputText id="vcfFileUrl" value={vcfFileUrl} style={{ marginTop: '4px' }} disabled required />
                                </div>
                                <CopyToClipboard text={vcfFileUrl} onCopy={() => toast.current.show({ severity: 'success', summary: '', detail: 'URL copied', life: 2000 })}>
                                    <Button tooltip="Copy to clipboard" className="p-button p-button-rounded p-button-icon-only" icon="pi pi-copy" style={{ marginTop: '32px' }} />
                                </CopyToClipboard>
                            </div> 
                            { qrCodeData && 
                            <div style={{ textAlign: 'center' }}>
                                <img src={qrCodeData} alt="QR Code" width="240" className="mt-0 mx-auto mb-5 block" />
                            </div>
                            }
                        </>
                        }
                    </Dialog>
                </div>
            </div>
        </div>
    )
}
