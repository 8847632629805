import axios from 'axios';
import { Storage } from "aws-amplify";
import each from 'async/each';
import moment from 'moment';


export class VccService {

    constructor() {
        this.cloudfrontBaseUrl = process.env.REACT_APP_CLOUDFRONT_BASE_URL;
        this.baseDir = 'data';
        this.imageDir = 'images';
        this.archiveDir = '_archive';
    }

    getAllArchivedContacts() {
        return this.getAllContacts(this.archiveDir);
    }

    getAllContacts(dir) {
        const path = dir || this.baseDir;

        const items = {};
        const contacts = {};

        const dirwalk = (source, target, item) => {
            const elements = source.split("/");
            const element = elements.shift();
            if (!element) return // blank
            if (elements.length) {
                target[element] = typeof target[element] === "object" ? target[element] : {};
                dirwalk(elements.join("/"), target[element], item);
            }
        };

        return Storage.list(path)
            .then(results => {
                results.forEach(item => dirwalk(item.key, items, item));
                Object.keys(items[path]).forEach(uid => {
                    if (uid.indexOf('-') < 0) {
                        uid = uid + "/" + Object.keys(items[path][uid])[0];
                    }

                    const organization = uid.indexOf('--') >= 0 ? uid.split("--").shift() : uid.split("-").shift();
                    const person = uid.indexOf('--') >= 0 ? uid.substring(uid.indexOf('--') + 2) : uid.substring(uid.indexOf('-') + 1);

                    if (!contacts[organization]) {
                        contacts[organization] = {
                            "key": organization,
                            "data": {
                                "name": organization,
                                "uid": ""
                            },
                            "children": [],
                            "leaf": false
                        };
                    }

                    contacts[organization]["children"].push({
                        "key": uid,
                        "data": {
                            "name": person,
                            "uid": uid
                        },
                        "children": [],
                        "leaf": true
                    });
                });

                return Object.values(contacts);
            })
            .catch(err => console.log(err));
    }

    getArchivedContact(contactUID) {
        return this.getContact(contactUID, this.archiveDir);
    }

    getContact(contactUID, dir) {
        const path = dir || this.baseDir;

        let result = {
            data: {},
            isSynced: false,
            avatarUrl: '',
            vcfFileUrl: '',
            rev: ''
        };
        return Storage.get(`${path}/${contactUID}/primary.json`)
            .then(res => {
                return axios.get(res);
            })
            .then(res => {
                result.data = res.data;
                return axios.get(`${this.cloudfrontBaseUrl}${path}/${contactUID}/primary.json?ts=${Date.now()}`);
            })
            .then(res => {
                result.rev = res.data.rev;
                result.isSynced = (result.data.rev === res.data.rev);
                return Storage.get(`${path}/${contactUID}/avatar.jpg`)
            })
            .then(res => {
                result.avatarUrl = res;
                result.vcfFileUrl = `${this.cloudfrontBaseUrl}${path}/${contactUID}/primary.vcf`;

                return result;
            });
    }

    async addContact(contact, contactList) {
        contact.created_on = moment().format();
        
        return await this.upsertContact(contact, true)
            .then(() => {
                const organization = contact.contactUID.indexOf('--') >= 0 ? contact.contactUID.split("--").shift() : contact.contactUID.split("-").shift();
                const person = contact.contactUID.indexOf('--') >= 0 ? contact.contactUID.substring(contact.contactUID.indexOf('--') + 2) : contact.contactUID.substring(contact.contactUID.indexOf('-') + 1);

                let index = this.findIndexByKey(contactList, organization);
                if (index < 0) {
                    contactList.push({
                        "key": organization,
                        "data": {
                            "name": organization,
                            "uid": ""
                        },
                        "children": [],
                        "leaf": false
                    });
                    index = contactList.length - 1;
                }

                contactList[index]["children"].push({
                    "key": contact.contactUID,
                    "data": {
                        "name": person,
                        "uid": contact.contactUID
                    },
                    "children": [],
                    "leaf": true
                });

                return {
                    'contacts': contactList,
                    'organization': organization
                };
            });
    }

    async archiveContacts(contactUIDs, contactList) {
        return await this.moveContacts(contactUIDs, contactList, this.baseDir, this.archiveDir);

    }

    async restoreContacts(contactUIDs, contactList) {
        return await this.moveContacts(contactUIDs, contactList, this.archiveDir, this.baseDir);
    }

    async moveContacts(contactUIDs, contactList, sourceDir, targetDir) {
        return each(
                    contactUIDs, 
                    function (contactUID, callback) {
                        return Storage.list(`${sourceDir}/${contactUID}/`)
                            .then(files =>
                                each(
                                    files,
                                    function (file, callback) {
                                        Storage.copy({key : file.key}, {key : `${targetDir}/${file.key.replace(`${sourceDir}/`, '')}`})
                                            .then(() => Storage.remove(file.key));
                                    },
                                    callback()
                                )
                            )
                    }
                )
                .then(() => {
                    for (let i = 0; i < contactList.length; i++) {
                        for (let j = 0; j < contactList[i]['children'].length; j++) {
                            if (contactUIDs.includes(contactList[i]['children'][j]['key'])) {
                                contactList[i]['children'].splice(j, 1);
                                j--;
                            }
                        }

                        if (contactList[i]['children'].length === 0) {
                            contactList.splice(i, 1); 
                            i--;
                        }
                    }

                    return {
                        'contacts': contactList
                    };
                })
                .catch(err => {
                    console.log(err);
                });
    }

    findIndexByKey(contactList, key) {
        let index = -1;
        for (let i = 0; i < contactList.length; i++) {
            if (contactList[i].key === key) {
                index = i;
                break;
            }
        }

        return index;
    }

    async updateContact(contact) {
        await this.upsertContact(contact, false);
    }

    async upsertContact(contact, persistAvatar) {
        contact.full_name = `${contact.first_name} ${contact.last_name}`;
        contact.rev = new Date().toISOString();

        const vcfTemplate = // eslint-disable-next-line
            `BEGIN:VCARD` + "\n" + // eslint-disable-next-line
            `VERSION:3.0` + "\n" + // eslint-disable-next-line
            `FN;CHARSET=UTF-8:${contact.first_name} ${contact.last_name}` + "\n" + // eslint-disable-next-line
            `N;CHARSET=UTF-8:${contact.last_name};${contact.first_name};;;` + "\n" + // eslint-disable-next-line
            `UID;CHARSET=UTF-8:${contact.uid}` + "\n" + // eslint-disable-next-line
            `EMAIL;CHARSET=UTF-8;type=Personal,INTERNET:${contact.email}` + "\n" + // eslint-disable-next-line
            `EMAIL;CHARSET=UTF-8;type=Business,INTERNET:${contact.work_email}` + "\n" + // eslint-disable-next-line
            `PHOTO;ENCODING=b;TYPE=JPEG:${contact.user_avatar_data}` + "\n" + // eslint-disable-next-line
            `TEL;TYPE=Personal:${contact.mobile_phone}` + "\n" + // eslint-disable-next-line
            `TEL;TYPE=Business,VOICE:${contact.work_phone}` + "\n" + // eslint-disable-next-line
            `ADR;CHARSET=UTF-8;TYPE=Business Address:;;${contact.address_line_1} ${contact.address_line_2};${contact.city};${contact.county};${contact.postal_code};${contact.country}` + "\n" + // eslint-disable-next-line
            `TITLE;CHARSET=UTF-8:${contact.job_title}` + "\n" + // eslint-disable-next-line
            `ORG;CHARSET=UTF-8:${contact.organization}` + "\n" + // eslint-disable-next-line
            `URL;type=Website;CHARSET=UTF-8:${contact.work_url}` + "\n" + // eslint-disable-next-line
            (contact.linkedin_url && ("" + contact.linkedin_url).trim().length > 0 ? (`URL;TYPE=LinkedIn:${contact.linkedin_url}` + "\n") : "") + // eslint-disable-next-line
            (contact.facebook_url && ("" + contact.facebook_url).trim().length > 0 ? (`URL;TYPE=Facebook:${contact.facebook_url}` + "\n") : "") + // eslint-disable-next-line
            (contact.instagram_url && ("" + contact.instagram_url).trim().length > 0 ? (`URL;TYPE=Instagram:${contact.instagram_url}` + "\n") : "") + // eslint-disable-next-line
            (contact.youtube_url && ("" + contact.youtube_url).trim().length > 0 ? (`URL;TYPE=Youtube:${contact.youtube_url}` + "\n") : "") + // eslint-disable-next-line
            (contact.whats_app_url && ("" + contact.whats_app_url).trim().length > 0 ? (`URL;TYPE=WhatsApp:${contact.whats_app_url}` + "\n") : "") + // eslint-disable-next-line
            (contact.zalo_url && ("" + contact.zalo_url).trim().length > 0 ? (`URL;TYPE=Zalo:${contact.zalo_url}` + "\n") : "") + // eslint-disable-next-line
            `X-SOCIALPROFILE;TYPE=vCardCampus:${contact.contactUID}` + "\n" + // eslint-disable-next-line
            `NOTE;CHARSET=UTF-8:${contact.notes}` + "\n" + // eslint-disable-next-line
            `REV:${contact.rev}` + "\n" + // eslint-disable-next-line
            `END:VCARD`;

        await Storage.put(`${this.baseDir}/${contact.contactUID}/primary.vcf`, vcfTemplate);

        await Storage.put(`${this.baseDir}/${contact.contactUID}/primary.json`, JSON.stringify(contact, null, 2));
        await Storage.put(`${this.baseDir}/${contact.contactUID}/backup-${Math.random().toString(36).slice(6)}.json`, JSON.stringify(contact, null, 2));

        if (persistAvatar && contact.user_avatar_data) {
            await Storage.copy({ key: `${this.imageDir}/${contact.uid}/avatar.jpg` }, { key: `${this.baseDir}/${contact.contactUID}/avatar.jpg` });
        }
    }

    async updateAvatar(contactUID, file) {
        return await this.uploadFile(`${this.baseDir}/${contactUID}/avatar.jpg`, file);
    }

    async uploadTemporaryAvatar(uid, file) {
        return await this.uploadFile(`${this.imageDir}/${uid}/avatar.jpg`, file);
    }

    async uploadFile(path, data) {
        return await Storage.put(path, data)
            .then(res => {
                return Storage.get(path);
            });
    }
}
