import React, { useState, useEffect, useRef } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { VccService } from '../service/VccService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import { Badge } from 'primereact/badge';
import { confirmDialog } from 'primereact/confirmdialog';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import QRCode from 'qrcode';


export const VccContactsArchive = () => {

    let emptyContact = {
        uid: '',
        contactUID: '',
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        mobile_phone: '',
        user_avatar_data: '',
        organization: '',
        job_title: '',
        work_url: '',
        work_email: '',
        work_phone: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        county: '',
        postal_code: '',
        country: '',
        linkedin_url: '',
        facebook_url: '',
        instagram_url: '',
        youtube_url: '',
        whats_app_url: '',
        zalo_url: '',
        notes: '',
        rev: '',
        created_on: ''
    };
    const [contact, setContact] = useState(emptyContact);
    const [vcfFileUrl, setVcfFileUrl] = useState("");
    const [primaryRecordRevision, setPrimaryRecordRevision] = useState("");
    const [qrCodeData, setQRCodeData] = useState("");
    const [userAvatarUrl, setUserAvatarUrl] = useState("");
    const [contactDialog, setContactDialog] = useState(false);

    const [globalFilter, setGlobalFilter] = useState('');
    const treeTableFuncMap = {
        'globalFilter': setGlobalFilter,
    };
    const [treeTableNodes, setTreeTableNodes] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState({});
    const [expandAll, setExpandAll] = useState(false);

    const vccService = useRef(new VccService());

    const toast = useRef(null);


    useEffect(() => {
        vccService.current.getAllArchivedContacts().then(data => {
            setTreeTableNodes(data); 
            toggleAll(data);
        });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getHeader = (globalFilterKey) => {
        return (
            <div className="grid">
                <div className="col" style={{ textAlign: 'left' }}>
                    <Button tooltip={expandAll ? "Collapse" : "Expand"} icon={expandAll ? "pi pi-chevron-circle-up" : "pi pi-chevron-circle-down"} className="p-button-secondary" onClick={() => toggleAll()}/>
                </div>
                <div className="col">
                    <div className="text-right">
                        <div className="p-input-icon-left">
                            <i className="pi pi-search"></i>
                            <InputText type="search" value={globalFilter} onInput={(e) => treeTableFuncMap[`${globalFilterKey}`](e.target.value)} placeholder="Search" size="25" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    let header = getHeader('globalFilter');

    const toggleAll = (data) => {
        if (expandAll) {
            setExpandedKeys({});
        } else {
            let _expandedKeys = {};
            let nodes = data ? data : treeTableNodes;
            nodes.forEach(node => _expandedKeys[`${node.key}`] = true);
            setExpandedKeys(_expandedKeys); 
        }

        setExpandAll(!expandAll);
    };

    const restoreContacts = (entry) => {
        confirmDialog({
            message: `Are you sure you want to restore ${entry.leaf ? 'contact' : 'organization' }: ${entry.key} ?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptIcon: 'pi pi-check',
            accept: () => {
                vccService.current.restoreContacts(entry.leaf ? [entry.key] : entry.children.map(c => c.key), treeTableNodes).then(data => {
                    setTreeTableNodes(data['contacts']);

                    let _globalFilter = globalFilter || '';
                    setGlobalFilter(' ');
                    setGlobalFilter(_globalFilter);

                    toast.current.show({ severity: 'success', summary: 'Success', detail: `${entry.leaf ? 'Contact' : 'All contacts of organization' } restored`, life: 5000 });
                })
            },
            rejectIcon: 'pi pi-times',
            reject: () => {}
        });
    };

    const viewContact = (contact) => {
        vccService.current.getArchivedContact(contact.data.uid).then(ctt => {
            populateContactData(ctt.data);
            setUserAvatarUrl(ctt.data.user_avatar_data ? ctt.avatarUrl : '');
            setVcfFileUrl(ctt.vcfFileUrl);
            setPrimaryRecordRevision(ctt.rev);
            QRCode.toDataURL(ctt.vcfFileUrl, { errorCorrectionLevel: 'M' }, function (err, vcfFileUrlEncodedData) { setQRCodeData(vcfFileUrlEncodedData); });

            setContactDialog(true);
        });
    };

    const populateContactData = (ctt) => {
        if (!ctt['zalo_url']) {
            ctt['zalo_url'] = '';
        }
        if (!ctt['created_on']) {
            ctt['created_on'] = '';
        }

        setContact({ ...ctt });
    }

    const resetContactDialogData = () => {
        setContact(emptyContact);
        setUserAvatarUrl("");
        setVcfFileUrl("");
        setPrimaryRecordRevision("");
        setQRCodeData("");
    }

    const hideDialog = () => {
        setTimeout(() => resetContactDialogData(), 100);
        setContactDialog(false);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.leaf && <Button tooltip="View Contact" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" className="p-button-rounded p-button-success mr-2" onClick={() => viewContact(rowData)} />}
                <Button tooltip={rowData.leaf ? "Restore Contact" : "Restore Organization"} tooltipOptions={{ position: 'top' }} icon="pi pi-undo" className={`p-button-rounded mr-2 ${ rowData.leaf ? 'p-button-info' : 'p-button-help'}`} style={{marginLeft: (rowData.leaf ? '' : '2.8rem')}} onClick={() => restoreContacts(rowData)} />
            </div>
        );
    }

    const contactDialogFooter = (
        <div className="grid">
            <div className="col" style={{ textAlign: 'left' }}>
            </div>
            <div className="col">
                <Button label="Close" className="p-button-text p-button-secondary" onClick={hideDialog} />
            </div>
        </div>
    );


    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <h3>Archived Contacts</h3>
                    <TreeTable value={treeTableNodes} header={header} globalFilter={globalFilter} expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)}>
                        {/* <Column field="name" header="Name" filter filterPlaceholder="Filter by Name" filterMatchMode="contains" headerStyle={{ width: '60%' }} expander /> */}
                        <Column field="name" header="Name" headerStyle={{ width: '57%' }} expander />
                        {/* <Column field="uid" header="UID" filter filterPlaceholder="Filter by UID" filterMatchMode="contains" headerStyle={{ width: '33%' }} /> */}
                        <Column field="uid" header="UID" headerStyle={{ width: '35%' }} />
                        <Column body={actionBodyTemplate} headerStyle={{ width: '8%' }} />
                    </TreeTable>

                    <Dialog visible={contactDialog} style={{ width: '480px' }} header="[Archived] Contact Details" modal className="p-fluid" footer={contactDialogFooter} onHide={hideDialog}>
                        { userAvatarUrl &&  
                        <div style={{ textAlign: 'center' }}>
                            <Image src={userAvatarUrl} alt="User Avatar" width="150" className="mt-0 mx-auto mb-5 block" preview />
                        </div>
                        }
                        <br/>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="first_name" className="p-text-secondary">First Name *</label>
                                <InputText id="first_name" value={contact.first_name} disabled required/>
                            </div>
                            <div className="field col">
                                <label htmlFor="last_name" className="p-text-secondary">Last Name *</label>
                                <InputText id="last_name" value={contact.last_name} disabled required/>
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="mobile_phone" className="p-text-secondary">Mobile Phone *</label>
                            <InputText id="mobile_phone" value={contact.mobile_phone} disabled required />
                        </div>
                        <div className="field">
                            <label htmlFor="email" className="p-text-secondary">Email</label>
                            <InputText id="email" value={contact.email} disabled />
                        </div>

                        <Divider />
                        <br/>

                        <div className="field">
                            <label htmlFor="organization" className="p-text-secondary">Organization</label>
                            <InputText id="organization" value={contact.organization} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="job_title" className="p-text-secondary">Job Title</label>    
                            <InputText id="job_title" value={contact.job_title} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="work_phone" className="p-text-secondary">Work Phone</label>
                            <InputText id="work_phone" value={contact.work_phone} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="work_email" className="p-text-secondary">Work Email</label>
                            <InputText id="work_email" value={contact.work_email} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="work_url" className="p-text-secondary">Website</label>
                            <InputText id="work_url" value={contact.work_url} disabled />
                        </div>

                        <Divider />
                        <br/>

                        <div className="field">
                            <label htmlFor="address_line_1" className="p-text-secondary">Address Line 1</label>
                            <InputText id="address_line_1" value={contact.address_line_1} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="address_line_2" className="p-text-secondary">Address Line 2</label>
                            <InputText id="address_line_2" value={contact.address_line_2} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="city" className="p-text-secondary">Town/City</label>
                            <InputText id="city" value={contact.city} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="county" className="p-text-secondary">County</label>
                            <InputText id="county" value={contact.county} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="postal_code" className="p-text-secondary">Postcode</label>
                            <InputText id="postal_code" value={contact.postal_code} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="country" className="p-text-secondary">Country</label>
                            <InputText id="country" value={contact.country} disabled />
                        </div>

                        <Divider />
                        <br/>

                        <div className="field">
                            <label htmlFor="linkedin_url" className="p-text-secondary">LinkedIn</label>
                            <InputText id="linkedin_url" value={contact.linkedin_url} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="facebook_url" className="p-text-secondary">Facebook</label>
                            <InputText id="facebook_url" value={contact.facebook_url} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="instagram_url" className="p-text-secondary">Instagram</label>
                            <InputText id="instagram_url" value={contact.instagram_url} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="youtube_url" className="p-text-secondary">Youtube</label>
                            <InputText id="youtube_url" value={contact.youtube_url} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="whats_app_url" className="p-text-secondary">WhatsApp</label>
                            <InputText id="whats_app_url" value={contact.whats_app_url} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="zalo_url" className="p-text-secondary">Zalo</label>
                            <InputText id="zalo_url" value={contact.zalo_url} disabled />
                        </div>

                        <Divider />
                        <br/>

                        <div className="field">
                            <label htmlFor="notes" className="p-text-secondary">Notes</label>
                            <InputTextarea id="notes" value={contact.notes} rows={5} cols={20} disabled />
                        </div>

                        <Divider />
                        <br/>

                        <div className="field">
                            <label htmlFor="contactUID" className="p-text-secondary">Contact Unique ID *</label>
                            <InputText id="contactUID" value={contact.contactUID} style={{ backgroundColor: '#FFE082', color: '#121212' }} disabled required />
                        </div>

                        <Divider />
                        <br/>

                        <div className="formgroup-inline">
                            <div className="field col">
                                <span>
                                    <label htmlFor="vcfFileUrl" className="p-text-secondary">vCard URL </label>
                                    {primaryRecordRevision && primaryRecordRevision.trim() !== '' && <Badge value={`rev: ${primaryRecordRevision}`} severity="success"></Badge>}
                                </span>
                                <InputText id="vcfFileUrl" value={vcfFileUrl} style={{ marginTop: '4px' }} disabled required />
                            </div>
                            <CopyToClipboard text={vcfFileUrl} onCopy={() => toast.current.show({ severity: 'success', summary: '', detail: 'URL copied', life: 2000 })}>
                                <Button tooltip="Copy to clipboard" className="p-button p-button-rounded p-button-icon-only" icon="pi pi-copy" style={{ marginTop: '32px' }} />
                            </CopyToClipboard>
                        </div> 
                        { qrCodeData && 
                        <div style={{ textAlign: 'center' }}>
                            <img src={qrCodeData} alt="QR Code" width="240" className="mt-0 mx-auto mb-5 block" />
                        </div>
                        }
                    </Dialog>
                </div>
            </div>
        </div>
    )
}
