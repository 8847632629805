/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:cdbd7e54-cd19-4cba-976e-8a148c5ade08",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_Ty8vqAbxu",
    "aws_user_pools_web_client_id": "17jrl66q4u61798uptml1m7fts",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_user_files_s3_bucket": "vcard-contacts203408-prod",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
